import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Building from './Building';
import NavBar from './NavBar';
import axios from 'axios';

// const buildingsData = [
//   {
//     floors: 3,
//     dorsPerFlore: [0, 1, 2],
//     name: 'Immeuble Doha',
//     facade: '1',
//     surface: '150m'
//   },
//   {
//     floors: 9,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Atlas',
//     facade: '2',
//     surface: '250m'
//   },
//   {
//     floors: 1,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Irfan',
//     facade: '3',
//     surface: '150m'
//   },
//   {
//     floors: 9,
//     dorsPerFlore: [0, 1, 2],
//     name: 'Immeuble Doha',
//     facade: '1',
//     surface: '150m'
//   },
//   {
//     floors: 7,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Atlas',
//     facade: '2',
//     surface: '250m'
//   },
//   {
//     floors: 6,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Irfan',
//     facade: '3',
//     surface: '150m'
//   },
//   {
//     floors: 4,
//     dorsPerFlore: [0, 1, 2],
//     name: 'Immeuble Doha',
//     facade: '1',
//     surface: '150m'
//   },
//   {
//     floors: 5,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Atlas',
//     facade: '2',
//     surface: '250m'
//   },
//   {
//     floors: 1,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Irfan',
//     facade: '3',
//     surface: '150m'
//   },
//   {
//     floors: 5,
//     dorsPerFlore: [1, 4, 3,5],
//     name: 'Immeuble Atlas',
//     facade: '2',
//     surface: '250m'
//   },
//   {
//     floors: 6,
//     dorsPerFlore: [1, 4, 3],
//     name: 'Immeuble Irfan',
//     facade: '3',
//     surface: '150m'
//   },
//   // Add more building data objects as needed
// ];

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const getMaxFloors = (buildings) => {
  let maxFloors = 0;
  buildings.forEach(building => {
    if (building.floors > maxFloors) {
      maxFloors = building.floors;
    }
  });
  return maxFloors;
};

const ListImobilier = () => {
  const [buildingsData , setbuildingsData] = useState([])
  useEffect(() => {
    axios.get("https://immobilierserv.yuliagroup.com/api/immeubles")
    .then((res) => {
        const transformedData = res.data.immeubles.map(immeuble => {
            console.log(immeuble);
            const doorsPerFloor = Array.from({ length: immeuble.Etage }, (_, index) => {
                const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
                return matchingAppartements.length;
            });

            const facadeData = Array.from({ length: immeuble.Etage }, (_, index) => {
                const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
                const facade = matchingAppartements.map(appartement => appartement.Nombre_de_Facade);
                return facade;
            });

            const surfaceData = Array.from({ length: immeuble.Etage }, (_, index) => {
                const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
                const surface = matchingAppartements.map(appartement => appartement.Surface);
                return surface;
            });
            const NumbereData = Array.from({ length: immeuble.Etage }, (_, index) => {
              const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
              const number = matchingAppartements.map(appartement => appartement.Numero);
              return number;
          });
          const StateData = Array.from({ length: immeuble.Etage }, (_, index) => {
            const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
            const Etat = matchingAppartements.map(appartement => appartement.Etat);
            return Etat;
        });
        const PrixeData = Array.from({ length: immeuble.Etage }, (_, index) => {
          const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
          const Prix = matchingAppartements.map(appartement => appartement.Prix);
          return Prix;
      });

            return {
                floors: immeuble.Etage,
                dorsPerFlore: doorsPerFloor,
                facade: facadeData,
                surface: surfaceData,
                name: immeuble.Nom_Im,
                Numero:NumbereData,
                Etat:StateData,
                Prix:PrixeData
            };
        });

        setbuildingsData(transformedData);
    })
}, []);

  const rows = chunkArray(buildingsData, 3);
  return (
    <>
    <NavBar/>
    <Grid container>
      {rows.map((row, index) => {
        const buildingHeight = getMaxFloors(row) * 170;
        const maxflors = getMaxFloors(row)
        return(
        <div key={index} style={{ display: 'flex', marginBottom: '20px', height: buildingHeight  , width:"100%"}} onMouseEnter={console.log(buildingsData)}>
          {row.map((building, i) => (
            <Grid item xs={12} sm={6} md={4} key={i} >
              <Building {...building} buildingHeight={buildingHeight} maxflors={maxflors} />
            </Grid>
          ))}
        </div>)
      })}
    </Grid>
    </>
  );
};

export default ListImobilier;