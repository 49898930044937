import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import NavBar from './NavBar';

function createData(id, nom, prenom, prix, de, a) {
    return { id, nom, prenom, prix, de, a };
}

const rows = [
    createData(1, 'Admin', 'Admin', '3.000', '18-05-2021', '18-05-2021'),
    createData(2, 'Alami', 'Ahmed', '3.000', '18-06-2021', '18-05-2021'),
    createData(3, 'Alaoui', 'Ahmed', '3.000', '18-07-2021', '18-05-2021'),
    createData(4, 'Amine', 'Amine', '3.500', '18-08-2021', '18-05-2021'),
    createData(5, 'Mohamed', 'Mohamed', '3.500', '18-09-2021', '18-05-2021'),
];

export default function HistoriqueAppartement() {
    return (
        <>
        <NavBar/>
        <Card style={{ width: '50%', height: '90%', margin: 'auto', marginTop: '9%' , borderRadius:'35px',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2  )'}}>
            <CardContent style={{ width: '90%', margin: 'auto', marginTop: '0px' }} >
                <h3
                    style={{
                        display: "flex",
                        justifyContent: "start",
                        width: "55%",
                        margin: '10px',
                    }}
                >
                    Historique De Doha Apartement N4
                </h3>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#D94E28' }}>
                                <TableCell style={{color:'white'}} >ID</TableCell>
                                <TableCell align="left" style={{color:'white'}} >Nom</TableCell>
                                <TableCell align="left"style={{color:'white'}} >Prenom</TableCell>
                                <TableCell align="left"style={{color:'white'}} >Prix</TableCell>
                                <TableCell align="left"style={{color:'white'}} >De</TableCell>
                                <TableCell >A</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.id}</TableCell>
                                    <TableCell align="left">{row.nom}</TableCell>
                                    <TableCell align="left">{row.prenom}</TableCell>
                                    <TableCell align="left">{row.prix}</TableCell>
                                    <TableCell align="left">{row.de}</TableCell>
                                    <TableCell align="left">{row.a}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        </Card>
        </>
    );
}