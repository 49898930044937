import * as React from 'react';
import { MenuItem, TextField } from '@mui/material';
import NavBar from "./NavBar.js";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useState } from 'react';
const styles = {
   
    label:{
        width:'100px'
    },
};



export default function Ajouterprop() {
    const [nom , setnom ] = useState()
    const [prenom,setprenom]=useState()
    const [login , setlogin] = useState()
    const [password , setpassword] = useState()
    const [role , setrole] = useState("")
    const handlsubmit=(e)=>{
        e.preventDefault()
    const data = {
        Nom_PR : nom ,
        Prenom_PR : prenom ,
        Login : login ,
        Password : password ,
        Role : role
    }
    axios.post("https://immobilierserv.yuliagroup.com/api/propritaire",data)
    .then((res)=>{
        console.log(res.data)
    })
    }
    return (
        <div>
        <NavBar/>
        <Grid 
            style={{
                minHeight: '70vh',
                margin: 'auto',
                maxWidth: '65%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2  )',
                borderRadius: '35px',
                marginTop:'9%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:"column",
                
            }}>
             <div style={{display:"flex",justifyContent:"start",paddingLeft:"80px" , width:"100%", margin:'10px' }}><h2>Ajouter un propriétaire</h2></div>
          
                     <form onSubmit={handlsubmit}  style={{border: '2px solid #D94E28', borderRadius: '30px', marginBottom:'5%', boxShadow: '0 4px 8px #D94E28'}}>
                            
                         <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Nom:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} value={nom} onChange={(e)=>setnom(e.target.value)} InputProps={{style: {borderRadius: "16px",}}}>  </TextField>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Prenom:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} value={prenom} onChange={(e)=>setprenom(e.target.value)} InputProps={{style: {borderRadius: "16px",}}}>    </TextField>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>login:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} value={login} onChange={(e)=>setlogin(e.target.value)} InputProps={{style: {borderRadius: "16px",}}}>    </TextField>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Role:</label>
                            <TextField select fullWidth size='small' sx={{width:"300px"}} onChange={(e)=>setrole(e.target.value)} InputProps={{style: {borderRadius: "16px",}}}> 
                            <MenuItem value='admin'>admin</MenuItem> 
                            <MenuItem value='Standard'>Standard</MenuItem> 
                              </TextField>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Password:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} type='password' value={password} onChange={(e)=>setpassword(e.target.value)} InputProps={{style: {borderRadius: "16px",}}}>    </TextField>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                          <Button type='submit' variant="outlined" color='warning' sx={{ mb: 2, backgroundColor: '#fff',color: '','&:hover': {backgroundColor: '#D94E28',color: '#fff', }, }} >
                    Ajouter
                </Button></div>
               </form>
            </Grid>
        </div>
    );
}
