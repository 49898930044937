import React, { useState } from 'react';
import { Button, Modal, Box, Typography } from '@mui/material';

export default function DeleteConfirmationModal({ open, onClose, onConfirm }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxWidth: 400,
          textAlign: 'center'
        }}
      >
        <Typography variant="h5" gutterBottom>
        Êtes-vous sûr(e) de vouloir supprimer cet élément ?"
        </Typography>
        <Button onClick={onConfirm} variant="contained" color="error" sx={{ mr: 2 }}>
          Yes
        </Button>
        <Button onClick={onClose} variant="contained" color="success">
          No
        </Button>
      </Box>
    </Modal>
  );
}