// Building.js

import React, { useEffect, useRef, useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import Konva from 'konva';
import Img from '../img/porte.png';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import { Button, MenuItem, TextField } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useNavigate } from 'react-router-dom';
import TabsComponent from './Tabs';
import DeleteIcon from '@mui/icons-material/Delete';
import axios, { AxiosHeaders } from 'axios';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import AddLocatair from './AddLocatair';
const CrudBuilding = ({ floors, dorsPerFlore, name, facade, surface,buildingHeight ,Numero,Etat,Prix ,id_im,ID_Appartement,R,getimmeuble}) => {
  const containerId = `container_${Math.random().toString(36).substring(7)}`;
  const tooltipRef = useRef();
  const navigate = useNavigate()
  const [cardContent, setCardContent] = useState({});
  const [activeDoor, setActiveDoor] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openi, setOpeni] = React.useState(false);
  const [editon,setediton] = useState(false)
  const handleOpeni = () => setOpeni(true);
  const handleClosei = () => setOpeni(false);
  const [openl, setOpenl] = React.useState(false);
  const handleOpenl = () => setOpenl(true);
  const handleClosel = () => {
    setOpenl(false)
  getlocatair()
  }
  const handleOpenediton = () => setediton(true);
  const handleCloseediton = () => {
    setOpen(false);
    setediton(false);
  }
  
  const [iddor,setiddor] = useState()
  const [formData, setFormData] = useState({
    ID_Im:id_im,
    id_locatair:1,
    Etage: '',
    Numero: '',
    Prix: '',
    Etat: '',
    Nombre_de_Facade: '',
    Surface: ''
  });
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #D94E28',
    borderColor: '#D94E28',
    boxShadow: 24,
    p: 4,
  };
  const styleT = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #D94E28',
    borderColor: '#D94E28',
    boxShadow: 24,
    p: 4,
    display:"flex",
    justifyContent:"center",

  };
  const getDorData=(id)=>{
    axios.get("https://immobilierserv.yuliagroup.com/api/appartements/"+id)
    .then((res)=>{
      setFormData({
        id_locatair:res.data.appartement.id_locatair,
        ID_Im:id_im,
        Etage: res.data.appartement.Etage,
        Numero: res.data.appartement.Numero,
        Prix: res.data.appartement.Prix,
        Etat: res.data.appartement.Etat,
        Nombre_de_Facade: res.data.appartement.Nombre_de_Facade,
        Surface: res.data.appartement.Surface
      })
    })
  }
  useEffect(() => {
    var stage = new Konva.Stage({
      container: containerId,
      width: 420,
      height: buildingHeight + 50,
    });
    var layer = new Konva.Layer();
    let addLine = false;
    for (let i = 0; i < floors; i++) {
      var flore = new Konva.Rect({
        x: 30,
        y:  buildingHeight - ((floors - (floors - i - 1)) * 142),
        width: 370,
        height: 130,
        fill: R === 0 ?  i > 1 ?  '#f5f5fa':'#b3b1b1' : R === 1 ?  i !== 0 ?  '#f5f5fa':'#b3b1b1':'#f5f5fa'   ,
        stroke: 'black',
        strokeWidth: 2
      });
      layer.add(flore);

      flore.zIndex(0);

      const floorIndex = floors - (floors - i - 1);
      let floorName;
      if (floorIndex === 1) {
        floorName = 'R-2';
      } else if (floorIndex === 2) {
        floorName = 'R-1';
        addLine = true;
      } else if (floorIndex === 3) {
        floorName = 'RDC';
      } else {
        floorName = `Etage ${i - 3 + 1}`;
      }
      if (addLine && floorIndex === 2) {
        var line = new Konva.Line({
          y: -18,
          points: [20, flore.y() + 12, 410, flore.y() + 12],
          stroke: 'black',
          strokeWidth: 3
        });
        layer.add(line);
        addLine = false;
      }

      var floorText = new Konva.Text({
        x: flore.x() + 10,
        y: flore.y() + 90,
        text: floorName,
        fontSize: 20,
        fill: 'black',
        rotation: -90
      });
      layer.add(floorText);

      for (let j = 0; j < dorsPerFlore[i]; j++) {
        const dorX = flore.x() + ((j + 0.5) * (flore.width() - 50) / dorsPerFlore[i]);
        const dorY = flore.y() + 29;

        var dor = new Konva.Rect({
          x: dorX + 10,
          y: dorY - 10,
          width: 40,
          height: 90,
          fill: Etat[i][j] === "1"?'rgba(255, 0, 0, 0.5)':Etat[i][j] === "0"?'rgba(20, 255, 0, 0.5)':null,
          strokeWidth: 4
        });

        layer.add(dor);

        var dorImage = new window.Image();
        dorImage.src = Img;

        dorImage.onload = () => {

          var dorImageRect = new Konva.Rect({
            x: dorX,
            y: dorY - 10,
            width: 50,
            height: 90,
            fillPatternImage: dorImage,
            fillPatternScaleX: 0.25,
            fillPatternScaleY: 0.35,
            strokeWidth: 4
          });
          layer.add(dorImageRect);
          dorImageRect.moveToTop();
          var text = new Konva.Text({
            x: Numero[i][j].toString().length === 1 ? (dorX + 25) : Numero[i][j].toString().length === 2 ? (dorX + 19) :Numero[i][j].toString().length === 3 ? (dorX + 16) :(dorX + 16),
            y: dorY + 10,
            text: Numero[i][j].toString(),
            fontSize: Numero[i][j].toString().length === 1 ? 25 : Numero[i][j].toString().length === 2 ? 23 :Numero[i][j].toString().length === 3 ? 21 :20,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 1.3,
          });
          layer.add(text);
          text.on('click', (e) => {
            getlocatair()
            handleOpen();
            getDorData(ID_Appartement[i][j])
            setiddor(ID_Appartement[i][j])
            setCardContent({
              surface:surface[i][j]+" m²",
              facade:facade[i][j],
              name,
              door:Numero[i][j],
              Prix:Prix[i][j]
            });
          });
          stage.batchDraw();

          dorImageRect.on('click', (e) => {
            getlocatair()
            handleOpen();
            getDorData(ID_Appartement[i][j])
            setiddor(ID_Appartement[i][j])
            setActiveDoor(dorImageRect);
            setCardContent({
              surface:surface[i][j]+" m²",
              facade:facade[i][j],
              name,
              door:Numero[i][j],
              Prix:Prix[i][j]
            });
          });
        };
      }
    }

    var tempText = new Konva.Text({
      text: name,
      fontSize: 20,
    });
    
    // Add the temporary text object to an invisible layer to avoid rendering
    var invisibleLayer = new Konva.Layer();
    invisibleLayer.add(tempText);
    stage.add(invisibleLayer);
    
    // Measure the width of the temporary text object
    var textWidth = tempText.width();
    
    // Calculate the x-coordinate to center the text horizontally
    var centerX = (stage.width() - textWidth) / 2;
    
    // Remove the temporary text object and invisible layer
    invisibleLayer.destroy();
    
    // Create the actual buildingNameText with the calculated x-coordinate
    var buildingNameText = new Konva.Text({
      x: centerX + 5,
      y: buildingHeight + 15,
      text: name,
      fontSize: 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 2
    });
    layer.add(buildingNameText);
    var buildingNameBorder = new Konva.Rect({
      x: 30,
      y: buildingHeight - 1,
      width: flore.width(),
      height: buildingNameText.height() + 30,
      stroke: 'black',
      strokeWidth: 2,
      cornerRadius: 10
    });
    layer.add(buildingNameBorder);
    stage.add(layer);

    var buttonWidth = 50; // Adjust the width of the button as needed
    var buttonHeight = 30; // Adjust the height of the button as needed
    var button = new Konva.Rect({
      x: buildingNameBorder.x() +15 , // Adjust the position of the button here
      y: buildingHeight +10, // Adjust the vertical position of the button as needed
      width: buttonWidth,
      height: buttonHeight,
      fill: '#fff', // Adjust the fill color of the button as needed
      stroke: '#D94E28',
      cornerRadius:5,
      strokeWidth: 2,
    });
    layer.add(button);
    var buttonText = new Konva.Text({
        x: button.x() + 14, // Adjust the horizontal position of the text inside the button as needed
        y: button.y() + 6, // Adjust the vertical position of the text inside the button as needed
        text: '✎', // Text to be displayed inside the button
        fontSize: 22, // Adjust the font size of the text as needed
        fill: '#D94E28', // Adjust the fill color of the text as needed
      });
      layer.add(buttonText);
      
    //   buttonText.zIndex=3
      button.on('click', () => {
        // Define the action you want to take when the button is clicked
        handleOpeni() // Example action: Log a message to the console
        
        // You can replace the console.log statement with any action you want
        // For example, you can navigate to a different page, open a modal, etc.
      });
      buttonText.on('click', () => {
        // Define the action you want to take when the button is clicked
        handleOpeni() // Example action: Log a message to the console
        // You can replace the console.log statement with any action you want
        // For example, you can navigate to a different page, open a modal, etc.
      });
      buildingNameBorder.zIndex=2
      buildingNameText.zIndex=2
      button.zIndex=5

      var buttondelete = new Konva.Rect({
        x: buildingNameBorder.width() - buttonWidth +10 , // Adjust the position of the button here
        y: buildingHeight +10, // Adjust the vertical position of the button as needed
        width: buttonWidth,
        height: buttonHeight,
        fill: '#fff', // Adjust the fill color of the button as needed
        stroke: 'red',
        cornerRadius:5,
        strokeWidth: 2,
      });
      layer.add(buttondelete);
      var buttonTextdelete = new Konva.Text({
          x: buttondelete.x() + 18, // Adjust the horizontal position of the text inside the button as needed
          y: buttondelete.y() + 6, // Adjust the vertical position of the text inside the button as needed
          text: '🗑', // Text to be displayed inside the button
          fontSize: 25, // Adjust the font size of the text as needed
          fill: 'red', // Adjust the fill color of the text as needed
        });
        layer.add(buttonTextdelete);
        
      //   buttonText.zIndex=3
        buttondelete.on('click', () => {
          // Define the action you want to take when the button is clicked
          handleOpenModalim() // Example action: Log a message to the console
          
          // You can replace the console.log statement with any action you want
          // For example, you can navigate to a different page, open a modal, etc.
        });
        buttonTextdelete.on('click', () => {
          // Define the action you want to take when the button is clicked
          handleOpenModalim() // Example action: Log a message to the console
          // You can replace the console.log statement with any action you want
          // For example, you can navigate to a different page, open a modal, etc.
        });
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [floors, dorsPerFlore, containerId]);
  const [locatair , setlocatair] = useState([])
  const getlocatair = ()=>{
    axios.get("https://immobilierserv.yuliagroup.com/api/Locatair")
    .then((res)=>{
      setlocatair(res.data.locatair)
      console.log(res.data)
    })
  }

  const handleOutsideClick = (event) => {
    if (!tooltipRef.current || !tooltipRef.current.getStage()) return;
    const stage = tooltipRef.current.getStage();
    if (!stage) return;
    const clickedNode = stage.findOne('.' + event.target.className);
    if (!clickedNode) {
      if (activeDoor) {
        activeDoor.visible(false);
        setActiveDoor(null);
      }
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    // Here we use Axios to send the formData to the API endpoint
    axios.put('https://immobilierserv.yuliagroup.com/api/appartements/'+iddor, formData)
      .then(response => {
        console.log('Success:', response.data);
        handleCloseediton()
        getimmeuble()
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error response here
      });
  };

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmDelete = () => {
    // Your delete logic here
    handleDelete()
    // After deleting, close the modal
    setOpenModal(false);
  };

  const handleDelete = () => {
  
    // Here we use Axios to send the formData to the API endpoint
    axios.delete('https://immobilierserv.yuliagroup.com/api/appartements/'+iddor)
      .then(response => {
        console.log('Success:', response.data);
        handleCloseediton()
        getimmeuble()
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error response here
      });
  };
  const [openModalim, setOpenModalim] = useState(false);

  const handleOpenModalim = () => {
    setOpenModalim(true);
  };

  const handleCloseModalim = () => {
    setOpenModalim(false);
  };

  const handleConfirmDeleteim = () => {
    // Your delete logic here
    handleDeleteimeuble()
    // After deleting, close the modal
    setOpenModalim(false);
  };
  const handleDeleteimeuble = () => {
  
    // Here we use Axios to send the formData to the API endpoint
    axios.delete('https://immobilierserv.yuliagroup.com/api/immeubles/'+id_im)
      .then(response => {
        console.log('Success:', response.data);
        getimmeuble()
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error response here
      });
  };
  return (
    <>
      <div id={containerId} style={{display:"flex" , justifyContent:"center" }} ></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {!editon ? 
          <>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          immeuble :{cardContent.name}
          </Typography>
          <div style={{display:"flex" , columnGap:2}}>
          <Button variant="outlined" onClick={handleOpenediton}><EditIcon/></Button>
          <Button variant="outlined" color="error" onClick={handleOpenModal}><DeleteIcon/></Button>
          </div>
          </div>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Door Number :{cardContent.door}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Facade :{cardContent.facade}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Surface :{cardContent.surface}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Prix :{parseInt(cardContent.Prix)} DH
          </Typography>
          <Typography id="modal-modal-description" sx={{ display:"flex" , justifyContent:"center",mt:3 }}>
          <Button variant='outlined' onClick={()=>navigate("/HistoriqueAppartement")}>Historique de Appartement</Button>
          </Typography>
          </>: 
          <>
          <form onSubmit={handleSubmit}>
          <div style={{display:"flex",justifyContent:"space-between"}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          immeuble :{cardContent.name}
          </Typography>
          <Button variant="outlined" type='submit' color='success'><DoneIcon/></Button>
          </div>
          <TextField
            label="Étage"
            name="Etage"
            value={formData.Etage}
            onChange={handleInputChange}
            fullWidth
            select
            margin="normal"
          >
                  {R === 0 ? Array.from({ length: floors - 2 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1 === 1 ? "RDC" : "Etage " + ((index + 1) - 1)}
                    </MenuItem>
                  )):R === 1 ? Array.from({ length: floors - 1 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1 === 1? "R-1":index + 1 === 2 ? "RDC" : "Etage " + ((index + 1) - 2)}
                    </MenuItem>
                  )):R === 2 ? Array.from({ length: floors }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1 === 1 ? "R-2":index + 1 === 2? "R-1":index + 1 === 3 ? "RDC" : "Etage " + ((index + 1) - 3)}
                    </MenuItem>
                  )):null}
          </TextField>
          <TextField
            label="Numéro"
            name="Numero"
            value={formData.Numero}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prix"
            name="Prix"
            value={formData.Prix}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="État"
            name="Etat"
            value={formData.Etat}
            onChange={handleInputChange}
            fullWidth
            select
            margin="normal"
          >
            <MenuItem value={"0"}>Libre</MenuItem>
            <MenuItem value={"1"}>Loué</MenuItem>
          </TextField>
          {formData.Etat === "1"?
          <TextField
          label="locatair"
          name="id_locatair"
          value={formData.id_locatair}
          onChange={handleInputChange}
          fullWidth
          select
          margin="normal"
        >
          {locatair.map((loc)=>(
              <MenuItem key={loc.id_locatair} value={loc.id_locatair}>{loc.nom}</MenuItem>
          ))}
          <MenuItem sx={{color:"#D94E28"}} onClick={handleOpenl}><AddIcon/> Ajouter un Locatair</MenuItem>
        </TextField>
          :null}
          <TextField
            label="Nombre de Facade"
            name="Nombre_de_Facade"
            value={formData.Nombre_de_Facade}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Surface"
            name="Surface"
            value={formData.Surface}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </form>
          </>
          }
        </Box>
      </Modal>

      <Modal
        open={openi}
        onClose={handleClosei}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleT} >
        <TabsComponent id_im={id_im} etages={floors} R={R} getimmeuble={getimmeuble} handleClosei={handleClosei}/>
        </Box>
      </Modal>
      <Modal
        open={openl}
        onClose={handleClosel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleT} >
        <AddLocatair handleClose={handleClosel}/>
        </Box>
      </Modal>
      <DeleteConfirmationModal open={openModal} onClose={handleCloseModal} onConfirm={handleConfirmDelete} />
      <DeleteConfirmationModal open={openModalim} onClose={handleCloseModalim} onConfirm={handleConfirmDeleteim} />
    </>
  );
};

export default CrudBuilding;
