import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import logo from '../img/logo.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://yuliagroup.com/">
                yuliagroup
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const textFieldStyle = {
    borderRadius: '30px',
};


export default function SignIn() {
    const [login,setlogin]=React.useState()
    const [password , setpassword]=React.useState()
    const navigate = useNavigate()
    React.useEffect(()=>{
        const userData = JSON.parse(localStorage.getItem("userData"))
        if(userData){
            navigate("/Dashboard")
        }
    })
    const handleSubmit = (event) => {
        event.preventDefault();
        let data = {
            Login : login,
            Password : password
        }
        axios.post("https://immobilierserv.yuliagroup.com/api/signin",data)
        .then((res)=>{
            if(!res.data.error){
                const userData = JSON.stringify(res.data.user)
                localStorage.setItem("userData",userData)
                navigate("/Dashboard")
            }else{
                alert(res.data.message)
            }
        })
    };

    return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 18,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        border: '1px solid #ccc',
                        padding: '30px',
                        borderRadius: '50px',
                        boxShadow: '0px 5px 10px rgba(0, 0, 0, 0.5)',
                        textAlign:"center"
                    }}
                >
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img src={logo} alt='logo' style={{ width: '30%', height: '30%', borderRadius: '30%' }} />
                    </Box>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            value={login}
                            onChange={(e)=>setlogin(e.target.value)}
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />
                        <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            value={password}
                            onChange={(e)=>setpassword(e.target.value)}
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            style={textFieldStyle}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{ mt: 3, mb: 2, backgroundColor: '#D94E28','&:hover': { backgroundColor: 'white', color: '#D94E28' } }}
                            style={textFieldStyle}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 4, mb: 4 }} />
            </Container>
    );
}