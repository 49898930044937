import * as React from 'react';
import { MenuItem, Select, TextField } from '@mui/material';
import NavBar from "./NavBar.js";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

const styles = {
    label:{
        width:'100px'
    },
};

export default function Modifierprop() {
    const { id } = useParams();
    const [initialData, setInitialData] = useState({});
    const [formData, setFormData] = useState({
        Nom_PR: '',
        Prenom_PR: '',
        Login: '',
        Password: '',
        Role: ''
    });

    useEffect(() => {
        axios.get("https://immobilierserv.yuliagroup.com/api/propritaire/" + id)
            .then((res) => {
                
                const { Nom_PR, Prenom_PR, Login, Role } = res.data.propritaire;
                setInitialData({ Nom_PR, Prenom_PR, Login, Role });
                setFormData({ Nom_PR, Prenom_PR, Login, Role });
                
            })
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const dataToUpdate = Object.keys(formData).reduce((acc, key) => {
            if (formData[key] !== initialData[key]) {
                acc[key] = formData[key];
            }
            return acc;
        }, {});
        axios.put("https://immobilierserv.yuliagroup.com/api/propritaire/" + id, dataToUpdate)
            .then((res) => {
                console.log(res.data);
            });
    }

    return (
        <div>
            <NavBar />
            <Grid
                style={{
                    minHeight: '70vh',
                    margin: 'auto',
                    maxWidth: '65%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    borderRadius: '35px',
                    marginTop: '9%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: "column",
                }}>
                <div style={{ display: "flex", justifyContent: "start", paddingLeft: "80px", width: "100%", margin: '10px' }}><h2>Ajouter un propriétaire</h2></div>
                <form onSubmit={handleSubmit} style={{ border: '2px solid #D94E28', borderRadius: '30px', marginBottom: '5%', boxShadow: '0 4px 8px #D94E28' }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10%' }}>
                        <label style={styles.label}>Nom:</label>
                        <TextField name="Nom_PR" fullWidth size='small' sx={{ width: "300px" }} value={formData.Nom_PR} onChange={handleInputChange} InputProps={{ style: { borderRadius: "16px" } }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10%' }}>
                        <label style={styles.label}>Prenom:</label>
                        <TextField name="Prenom_PR" fullWidth size='small' sx={{ width: "300px" }} value={formData.Prenom_PR} onChange={handleInputChange} InputProps={{ style: { borderRadius: "16px" } }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10%' }}>
                        <label style={styles.label}>login:</label>
                        <TextField name="Login" fullWidth size='small' sx={{ width: "300px" }} value={formData.Login} onChange={handleInputChange} InputProps={{ style: { borderRadius: "16px" } }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10%' }}>
                        <label style={styles.label}>Role:</label>
                        <Select
                            name="Role"
                            fullWidth
                            value={formData.Role}
                            sx={{ width: "300px", borderRadius: "16px" }}
                            size='small'
                            onChange={handleInputChange}
                        >
                            <MenuItem value={"admin"}>admin</MenuItem>
                            <MenuItem value={"Standard"}>Standard</MenuItem>
                        </Select>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '10%' }}>
                        <label style={styles.label}>Password:</label>
                        <TextField name="Password" fullWidth size='small' sx={{ width: "300px" }} type='password' value={formData.Password} onChange={handleInputChange} InputProps={{ style: { borderRadius: "16px" } }} />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button type='submit' variant="outlined" color='warning' sx={{ mb: 2, backgroundColor: '#fff', color: '', '&:hover': { backgroundColor: '#D94E28', color: '#fff' } }} >
                            Modifier
                        </Button>
                    </div>
                </form>
            </Grid>
        </div>
    );
}
