import React, { useState } from 'react';
import { FormControlLabel, Switch, TextField, Button, Container, Grid } from '@mui/material';
import axios from 'axios';

const AddLocatair = (props) => {
  const [isEntreprise, setIsEntreprise] = useState(false);
        const [nom , setnom]=useState()
        const [ice ,setice] = useState()
        const [cin , setcin]= useState()
        const [scancin , setscan] = useState()
        const [numero ,setnumero] = useState()
  const handleSwitchChange = () => {
    setIsEntreprise(!isEntreprise);
  };

  const handlsubmit =(e)=>{
    e.preventDefault()
    let data;
    if(isEntreprise){
        data={
            nom : nom ,
            ICE : ice ,
            CIN_representent : cin ,
            numero_representent : numero,
            typelocatair:'entreprise'
        }
    }else{
        data={
            nom : nom ,
            typelocatair:'particulier',
            CIN:cin,
            numero:numero
        }
    }
    
    axios.post("https://immobilierserv.yuliagroup.com/api/Locatair",data)
    .then((res)=>{
        console.log(res.data)
        props.handleClose()
    })
  }
  return (
    <Container maxWidth="sm">
      <h1>Ajouter Un Locatair</h1>
      <FormControlLabel
      sx={{display:"flex",justifyContent:"center"}}
        control={<Switch checked={isEntreprise} onChange={handleSwitchChange} />}
        label="Entreprise"
      />
      <form style={{textAlign:"center"}} onSubmit={handlsubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nom"
              fullWidth
              value={nom}
              onChange={(e)=>{setnom(e.target.value)}}
              required
            />
          </Grid>
          {isEntreprise ? (
            <>
              <Grid item xs={12}>
                <TextField
                  label="ICE"
                  fullWidth
                  value={ice}
                  onChange={(e)=>{setice(e.target.value)}}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="CIN Représentant"
                  fullWidth
                  value={cin}
                  onChange={(e)=>{setcin(e.target.value)}}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Scan CIN"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numéro Représentant"
                  value={numero}
                  onChange={(e)=>{setnumero(e.target.value)}}
                  fullWidth
                  required
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <TextField
                  label="CIN"
                  fullWidth
                  value={cin}
                  onChange={(e)=>{setcin(e.target.value)}}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Scan CIN"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Numéro"
                  value={numero}
                  onChange={(e)=>{setnumero(e.target.value)}}
                  fullWidth
                  required
                />
              </Grid>
            </>
          )}
        </Grid>
        <Button variant="outlined" sx={{mt:2}} color="primary" type="submit">
          Ajouter
        </Button>
      </form>
    </Container>
  );
};

export default AddLocatair;
