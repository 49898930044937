import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios'; // Import Axios
import { Box, FormControlLabel, MenuItem, Modal, Radio, RadioGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AddLocatair from './AddLocatair';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

export default function TabsComponent(props) {
  const styleT = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #D94E28',
    borderColor: '#D94E28',
    boxShadow: 24,
    p: 4,
    display:"flex",
    justifyContent:"center",

  };
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({
    ID_Im:props.id_im,
    id_locatair:1,
    Etage: '',
    Numero: '',
    Prix: '',
    Etat: '',
    Nombre_de_Facade: '',
    Surface: ''
  });
  const [name , setname] = useState()
  const [etages , setetages]=useState()
  const [address , setaddress ] = useState()
  const [R , setR ] = useState(0)
  const [newname , setnewname] = useState()
  const [newetages , setnewetages]=useState()
  const [newaddress , setnewaddress ] = useState()
  const [newR , setnewR ] = useState()
  const [locatair , setlocatair] = useState([])
  const [openl, setOpenl] = React.useState(false);
  const handleOpenl = () => setOpenl(true);
  const handleClosel = () => {
  setOpenl(false)
  getlocatair()
  }
  const getlocatair = ()=>{
    axios.get("https://immobilierserv.yuliagroup.com/api/Locatair")
    .then((res)=>{
      setlocatair(res.data.locatair)
      console.log(res.data)
    })
  }
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  useEffect(()=>{
    getlocatair()
    axios.get("https://immobilierserv.yuliagroup.com/api/immeubles/"+props.id_im)
    .then((res)=>{
      setname(res.data.immeuble.Nom_Im)
      setaddress(res.data.immeuble.Address)
      setetages(res.data.immeuble.Etage )
      setR(res.data.immeuble.R)
      setnewR(res.data.immeuble.R)
    })
  },[])
  const handleSubmit = (event) => {
    console.log(formData)
    event.preventDefault();
    // Here we use Axios to send the formData to the API endpoint
    axios.post('https://immobilierserv.yuliagroup.com/api/appartements', formData)
      .then(response => {
        console.log('Success:', response.data);

        props.getimmeuble()
        props.handleClosei()
        // Handle success response here
      })
      .catch(error => {
        console.error('Error:', error);
        // Handle error response here
      });
  };
  const handleupdate =(e)=>{
    e.preventDefault()
    let data = {
        Nom_Im:name,
        Address:address,
        Etage:newetages ?parseInt(newetages)+1+parseInt(newR) :parseInt(newR)>parseInt(R)?(parseInt(newR)-parseInt(R)) + etages :parseInt(newR)<parseInt(R)?(parseInt(newR)-parseInt(R))+etages:etages ,
        Localisation:"{lap:10,long:20}",
        R:newR
    }
    axios.put("https://immobilierserv.yuliagroup.com/api/immeubles/"+props.id_im,data)
    .then(()=>{
      props.handleClosei()
        props.getimmeuble()
        setaddress("")
        setetages()
        setname("")
        setR(0)
    })
    
}
  return (
    <div >
      <Tabs value={value} onChange={handleChange} aria-label="apartment tabs">
        <Tab label="Ajouter Appartement" />
        <Tab label="Gestion Immeuble" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Étage"
            name="Etage"
            value={formData.Etage}
            onChange={handleInputChange}
            fullWidth
            select
            margin="normal"
          >
                  {props.R === 0 ? Array.from({ length: props.etages - 2 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1 === 1 ? "RDC" : "Etage " + ((index + 1) - 1)}
                    </MenuItem>
                  )):props.R === 1 ? Array.from({ length: props.etages - 1 }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1 === 1? "R-1":index + 1 === 2 ? "RDC" : "Etage " + ((index + 1) - 2)}
                    </MenuItem>
                  )):props.R === 2 ? Array.from({ length: props.etages }, (_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                      {index + 1 === 1 ? "R-2":index + 1 === 2? "R-1":index + 1 === 3 ? "RDC" : "Etage " + ((index + 1) - 3)}
                    </MenuItem>
                  )):null}
          </TextField>
          <TextField
            label="Numéro"
            name="Numero"
            value={formData.Numero}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Prix"
            name="Prix"
            value={formData.Prix}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="État"
            name="Etat"
            value={formData.Etat}
            onChange={handleInputChange}
            fullWidth
            select
            margin="normal"
          >
            <MenuItem value={"0"}>Libre</MenuItem>
            <MenuItem value={"1"}>Loué</MenuItem>
          </TextField>
          {formData.Etat === "1"?
          <TextField
          label="locatair"
          name="id_locatair"
          value={formData.id_locatair}
          onChange={handleInputChange}
          fullWidth
          select
          margin="normal"
        >
          {locatair.map((loc)=>(
              <MenuItem key={loc.id_locatair} value={loc.id_locatair}>{loc.nom}</MenuItem>
          ))}
          <MenuItem sx={{color:"#D94E28"}} onClick={handleOpenl}><AddIcon/> Ajouter un Locatair</MenuItem>
        </TextField>
          :null}
          <TextField
            label="Nombre de Facade"
            name="Nombre_de_Facade"
            value={formData.Nombre_de_Facade}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Surface"
            name="Surface"
            value={formData.Surface}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <div style={{textAlign:"center"}}>
            <Button type="submit" variant="outlined" sx={{mt:2}} color="primary">
            Submit
          </Button>
          </div>

        </form>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <form style={{textAlign:"center"}} onSubmit={handleupdate}>
            <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            value={name}
                            onChange={(e)=>{setname(e.target.value)}}
                            id="Nom d'immeuble"
                            label="Nom d'immeuble"
                            name="Nom d'immeuble"
                            autoComplete="Nom d'immeuble"
                        />
            <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            value={address}
                            onChange={(e)=>{setaddress(e.target.value)}}
                            id="َAddress d'immeuble"
                            label="َAddress d'immeuble"
                            name="َAddress d'immeuble"
                            autoComplete="َAddress d'immeuble"
                        />
            <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            type='number'
                            id="َN° Etages"
                            value={newetages?newetages:etages-1-R}
                            onChange={(e)=>{setnewetages(e.target.value)}}
                            label="َN° Etages"
                            name="َN° Etages"
                            autoComplete="َN° Etages"
                        />
                        {console.log("etage: "+etages+"  "+"R: "+R+" "+"newR :"+newR+" new etage: "+newetages)}
                        <div style={{display:"flex" ,justifyContent:"center" }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={newR?newR:R}
                            onChange={(e)=>{setnewR(e.target.value)}}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="RDC" />
                            <FormControlLabel value={1} control={<Radio />} label="R-1" />
                            <FormControlLabel value={2} control={<Radio />} label="R-2" />
                        </RadioGroup>
                        </div>
                <Button variant="outlined" type='submit'>Modifier</Button>
            </form>
      </TabPanel>
      <Modal
        open={openl}
        onClose={handleClosel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleT} >
        <AddLocatair handleClose={handleClosel}/>
        </Box>
      </Modal>
    </div>
  );
}
