import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  {
    name: 'november',
    pv: 22,
    amt: 30,
  },
 
];

export default function Barchart(){
    return (
     
          <BarChart
            width={200}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis dataKey="amt"/>
            <Tooltip />
            <Legend />
            <Bar dataKey="pv" fill="#8884d8" name='Appartement libre' activeBar={<Rectangle fill="pink" stroke="blue" />} />
          </BarChart>

      );
  }
