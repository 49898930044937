import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from './Logos/immobilier-es-sadiki-high-resolution-logo-transparent.png';
import { useNavigate } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { Divider } from '@mui/material';


export default function NavBar() { 

      const navigate = useNavigate()
    const [userData , setuserData ] = React.useState()
      const [anchorP, setAnchorP] = React.useState(null);
      const [loading , setloading ] = React.useState(true)
      const openP = Boolean(anchorP);
  const [anchorI, setAnchorI] = React.useState(null);
  const openI = Boolean(anchorI);
const [anchorEl, setAnchorEl] = React.useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClickP = (event) => {
    setAnchorP(event.currentTarget);
  };
  const handleCloseP = () => {
    setAnchorP(null);
  };
  const handleClickI = (event) => {
    setAnchorI(event.currentTarget);
  };
  const handleCloseI = () => {
    setAnchorI(null);
  };

 
  React.useEffect(()=>{
    setuserData(JSON.parse(localStorage.getItem("userData")))
    setloading(false)
  },[])
  
  const menuId = 'primary-search-account-menu';

  const handleLogout = () => {
    handleMenuClose();
    localStorage.removeItem('userData');
      navigate('/');
  };
  return (
    <>
    {!loading? 
    <Box sx={{ flexGrow: 1, }}>
      <AppBar sx={{bgcolor: "#FFFFFF", flexGrow: 1, textAlign: "center", alignItems: "center", display: 'flex', justifyContent: "center" }} >
        <Toolbar sx={{  alignContent:'left' }}>
         
          
            <img style={{ display:"flex" , width: "15%" , margin: "0.7%" }} src={Logo} alt="/" />
            
          <Box sx={{ flexGrow: 0.2 }} />
          <Box sx={{ flexGrow: 1, display: 'flex', columnGap: 6, ml: 10, height: "62.5px" }}>
          <Button style={{color:'black'}} onClick={()=>navigate("/Dashboard")}>
                Tableau de bord
              </Button>
          <Button
              style={{color:'black'}}
              aria-controls={openI ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openI ? 'true' : undefined}
              onClick={handleClickI}
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
            >
              Immobilier
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorI}
              open={openI}
              PaperProps={{
                sx: {
                  mt: "-1px",
                  borderRadius: 0,
                  borderTop: "solid 3px #D94E28"
                }
              }}
              onClose={handleCloseI}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={()=>{navigate("/listimbl")}}>Liste Immobilier</MenuItem>
                  <MenuItem onClick={()=>{navigate("/GestionImobilier")}}>Gestion Immobilier</MenuItem>
              </Menu>
            <Button
              style={{color:'black'}}
              aria-controls={openP ? 'fade-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openP ? 'true' : undefined}
              onClick={handleClickP}
              disableElevation
              endIcon={<KeyboardArrowDownIcon />}
            >
              propritaires
            </Button>
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorP}
              open={openP}
              PaperProps={{
                sx: {
                  mt: "-1px",
                  borderRadius: 0,
                  borderTop: "solid 3px #D94E28"
                }
              }}
              onClose={handleCloseP}
              TransitionComponent={Fade}
            >
              <MenuItem >Liste Propritaires</MenuItem>
                  <MenuItem onClick={() => navigate("/propritaire")}>Gestion Propritaires</MenuItem>
              </Menu>
              </Box>
          <Box sx={{ flexGrow: 1, alignContent:'left' }} />
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="black"
            >
              <Badge color="error">
                <NotificationsNoneOutlinedIcon style={{ fontSize: '32' }} />
              </Badge>
            </IconButton>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="black"
            >
              <AccountCircleOutlinedIcon style={{ fontSize: '32' }}/>
            </IconButton>
          
          
        </Toolbar>
      </AppBar>
      <Menu
              id="account-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.4,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem disabled>
                <Typography fontWeight="600" textAlign="center">Propritaires: {userData.Login}</Typography>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <Typography textAlign="center">Signout</Typography>
              </MenuItem>
            </Menu>
            
    </Box>
    :null}
    </>
  );
}