import React from 'react';
import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
  {
    month: '11-2023',
    numberpermonth: 30,
  },
  {
    month: '12-2023',
    numberpermonth: 20,
  },
  {
    month: '01-2024',
    numberpermonth: 15,
  },
];

export default function Barchartb(){
    return (
     
          <BarChart
            width={200}
            height={400}
            data={data}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis dataKey="numberpermonth"/>
            <Tooltip />
            <Legend />
            <Bar dataKey="numberpermonth" fill="#8884d8" name='Appartement loyee' activeBar={<Rectangle fill="pink" stroke="blue" />} />
          </BarChart>

      );
  }
