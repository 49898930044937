import * as React from 'react';
import { TextField } from '@mui/material';
import NavBar from "./NavBar.js";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button';


const styles = {
   
    label:{
        width:'100px'
    },
};

export default function Modifierimbl() {
    return (
        <div>
        <NavBar/>
        <Grid 
            style={{
                minHeight: '70vh',
                margin: 'auto',
                maxWidth: '65%',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2  )',
                borderRadius: '35px',
                marginTop:'9%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection:"column",
                
            }}>
             <div style={{display:"flex",justifyContent:"start",paddingLeft:"80px" , width:"100%", margin:'10px' }}><h2>Modifier Un Immeuble</h2></div>
          
                        <form  style={{border: '2px solid #D94E28', borderRadius: '30px', marginBottom:'5%',  boxShadow: '0 4px 8px #D94E28'}}>
                            
                         <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Nom:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} InputProps={{style: {borderRadius: "16px",}}}>  </TextField>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Address:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} InputProps={{style: {borderRadius: "16px",}}}>    </TextField>
                        </div>
                        
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Etage:</label>
                            <TextField type="number" fullWidth size='small' sx={{width:"300px"}} InputProps={{style: {borderRadius: "16px",}}}>    </TextField>
                        </div>
                
                        <div style={{display:'flex', justifyContent:'center',alignItems:'center',margin:'10%'}}>
                            <label style={styles.label}>Localisation:</label>
                            <TextField fullWidth size='small' sx={{width:"300px"}} InputProps={{style: {borderRadius: "16px",}}}>    </TextField>
                        </div>
                        <div style={{display:'flex', justifyContent:'center'}}>
                          <Button  variant="outlined" color='warning' sx={{ mb: 2, backgroundColor: '#fff',color: '','&:hover': {backgroundColor: '#D94E28',color: '#fff', }, }} >
                    Ajouter
                </Button></div>
               </form>
            </Grid>
        </div>
    );
}
