import React from 'react';
import NavBar from "./NavBar.js";
import PieCharts from './Piechart.js';
import Barcharts from './Barchart.js';
import Barchartb from './BarchartB.js';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';



export default function Dashboard() {
  const navigate = useNavigate()
  React.useEffect(()=>{
    const userData = JSON.parse(localStorage.getItem("userData"))
    if(!userData){
        navigate("/")
    }
})
  return (
    <div>
      <NavBar />
      <Grid
        style={{
          minHeight: '70vh',
          margin: 'auto',
          maxWidth: '65%',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2  )',
          borderRadius: '35px',
          marginTop: '9%',
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          flexDirection: "column",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
            <PieCharts /> 
             <Barcharts />
             <Barchartb/>
        </div>
        
      </Grid>
    </div>
  );
}
