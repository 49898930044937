import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControlLabel, FormLabel, Grid, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import CrudBuilding from './CrudBuilding';
import NavBar from './NavBar';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import SortIcon from '@mui/icons-material/Sort';
const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

const getMaxFloors = (buildings) => {
  let maxFloors = 0;
  buildings.forEach(building => {
    if (building.floors > maxFloors) {
      maxFloors = building.floors;
    }
  });
  return maxFloors;
};

const GestionImobilier = () => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  const [buildingsData , setbuildingsData] = useState([])
  const [name , setname] = useState()
  const [etages , setetages]=useState()
  const [address , setaddress ] = useState()
  const [R , setR ] = useState(0)

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #D94E28',
    borderColor: '#D94E28',
    boxShadow: 24,
    p: 4,
  };

  const getimmeuble = ()=>{
    axios.get("https://immobilierserv.yuliagroup.com/api/immeubles")
    .then((res) => {
        const transformedData = res.data.immeubles.map(immeuble => {
            const doorsPerFloor = Array.from({ length: immeuble.Etage }, (_, index) => {
                const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
                return matchingAppartements.length;
            });

            const facadeData = Array.from({ length: immeuble.Etage }, (_, index) => {
                const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
                const facade = matchingAppartements.map(appartement => appartement.Nombre_de_Facade);
                return facade;
            });

            const surfaceData = Array.from({ length: immeuble.Etage }, (_, index) => {
                const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
                const surface = matchingAppartements.map(appartement => appartement.Surface);
                return surface;
            });
            const NumbereData = Array.from({ length: immeuble.Etage }, (_, index) => {
              const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
              const number = matchingAppartements.map(appartement => appartement.Numero);
              return number;
          });
          const StateData = Array.from({ length: immeuble.Etage }, (_, index) => {
            const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
            const Etat = matchingAppartements.map(appartement => appartement.Etat);
            return Etat;
        });
        const PrixeData = Array.from({ length: immeuble.Etage }, (_, index) => {
          const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
          const Prix = matchingAppartements.map(appartement => appartement.Prix);
          return Prix;
      });
      const AppartementData = Array.from({ length: immeuble.Etage }, (_, index) => {
        const matchingAppartements = immeuble.immeubleappartement.filter(appartement => appartement.Etage === index + 1);
        const Appartement = matchingAppartements.map(appartement => appartement.ID_Appartement);
        return Appartement;
    });
    let etages = immeuble.Etage;
    if(immeuble.R === 0){
            doorsPerFloor.unshift(0,0)
            facadeData.unshift(0,0)
            surfaceData.unshift(0,0)
            NumbereData.unshift(0,0)
            StateData.unshift(0,0)
            PrixeData.unshift(0,0)
            AppartementData.unshift(0,0)
            etages = immeuble.Etage + 2
    }
    else if(immeuble.R === 1){
        doorsPerFloor.unshift(0)
        facadeData.unshift(0)
        surfaceData.unshift(0)
        NumbereData.unshift(0)
        StateData.unshift(0)
        PrixeData.unshift(0)
        AppartementData.unshift(0)
        etages = immeuble.Etage + 1
}
            return {
                id_im:immeuble.ID_Im,
                floors: etages,
                dorsPerFlore: doorsPerFloor,
                facade: facadeData,
                surface: surfaceData,
                name: immeuble.Nom_Im,
                Numero:NumbereData,
                Etat:StateData,
                Prix:PrixeData,
                ID_Appartement:AppartementData,
                R : immeuble.R
            };
        });

        setbuildingsData(transformedData);
    })
  }
  useEffect(() => {
    getimmeuble()
}, []);

  const rows = chunkArray(buildingsData, 3);

    const handleSubmit =(e)=>{
        e.preventDefault()
        let data = {
            Nom_Im:name,
            Address:address,
            Etage:parseInt(etages) + 1 + parseInt(R),
            Localisation:"{lap:10,long:20}",
            R:R
        }
        axios.post("https://immobilierserv.yuliagroup.com/api/immeubles",data)
        .then(()=>{
            handleClose()
            getimmeuble()
            setaddress("")
            setetages()
            setname("")
            setR(0)
        })
        
    }


  return (
    <>
    <NavBar/>
    <Grid container>
        <Grid sx={{mt:15 , zIndex:4}}>
            <div style={{width:"98%" , display:"flex" , justifyContent:"space-between"}}>
            <Typography variant='h4'>Gestion Immobilier</Typography>
            <div>
            <Button onClick={handleOpen}><AddIcon/></Button>
            <Button><SortIcon/></Button>
            </div>
            
            </div>
            
            <Divider sx={{width:"98vw",borderBottomWidth:2,borderColor:"black" , mt:-0.5}}/>
        </Grid>
      {rows.map((row, index) => {
        const buildingHeight = getMaxFloors(row) * 145;
        const maxflors = getMaxFloors(row)
        return(
        <div key={index} style={{ display: 'flex', marginBottom: '50px', height: buildingHeight  , width:"100%"}} onMouseEnter={console.log(buildingsData)}>
          {row.map((building, i) => (
            <Grid item xs={12} sm={6} md={4} key={i} >
              <CrudBuilding {...building} buildingHeight={buildingHeight} maxflors={maxflors} getimmeuble={getimmeuble} />
            </Grid>
          ))}
        </div>)
      })}
    </Grid>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Typography variant='h6'>Ajouter un Immeuble</Typography>
            <form style={{textAlign:"center"}} onSubmit={handleSubmit}>
            <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            value={name}
                            onChange={(e)=>{setname(e.target.value)}}
                            id="Nom d'immeuble"
                            label="Nom d'immeuble"
                            name="Nom d'immeuble"
                            autoComplete="Nom d'immeuble"
                        />
            <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            value={address}
                            onChange={(e)=>{setaddress(e.target.value)}}
                            id="َAddress d'immeuble"
                            label="َAddress d'immeuble"
                            name="َAddress d'immeuble"
                            autoComplete="َAddress d'immeuble"
                        />
            <TextField
                            color='warning'
                            margin="normal"
                            required
                            fullWidth
                            type='number'
                            id="َN° Etages"
                            value={etages}
                            onChange={(e)=>{setetages(e.target.value)}}
                            label="َN° Etages"
                            name="َN° Etages"
                            autoComplete="َN° Etages"
                        />
                        <div style={{display:"flex" ,justifyContent:"center" }}>
                        <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={R}
                            onChange={(e)=>{setR(e.target.value)}}
                        >
                            <FormControlLabel value={0} control={<Radio />} label="RDC" />
                            <FormControlLabel value={1} control={<Radio />} label="R-1" />
                            <FormControlLabel value={2} control={<Radio />} label="R-2" />
                        </RadioGroup>
                        </div>
                <Button variant="outlined" type='submit'>Ajouter</Button>
            </form>
                        
        </Box>
        
      </Modal>
    </>
  );
};

export default GestionImobilier;