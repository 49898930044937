import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; 
import axios from 'axios';
import { useEffect } from 'react';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';



export default function UtilisTable() {
    const [rows,setrows]=React.useState([])
    const navigate = useNavigate()
    const getpropritaire=()=>{
        axios.get('https://immobilierserv.yuliagroup.com/api/propritaire')
        .then((res)=>{
            setrows(res.data.propritaire)
        })
    }
    useEffect(()=>{
        getpropritaire()
    },[])

    const handleDelete = (id_propritaire) => {
        axios.delete("https://immobilierserv.yuliagroup.com/api/propritaire/" + id_propritaire)
            .then(() => {
                getpropritaire()
            })
            .catch(error => {
                console.error('Error deleting row:', error);
            });
    };

    return (
        <>
        <NavBar/>
        <Card style={{ width: '50%', height: '90%', margin: 'auto', marginTop: '9%', borderRadius:'35px',boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2  )' }}>
            <CardContent style={{ width: '90%', margin: 'auto', marginTop: '0px' }} >
                <Button variant="outlined" endIcon={<AddIcon />} color='warning' onClick={()=>navigate("/Ajouter-prop")} style={{display:"flex",justifyContent:"start" , margin:'10px' }}>
                    Ajoute un propritaire
                </Button>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow sx={{ backgroundColor: '#D94E28' }}>
                                <TableCell style={{color:'white'}} >ID</TableCell>
                                <TableCell align="left"style={{color:'white'}} >Nom</TableCell>
                                <TableCell align="left"style={{color:'white'}} >Prenom</TableCell>
                                <TableCell align="left"style={{color:'white'}} >Role</TableCell>
                                <TableCell align="left"style={{color:'white'}} >Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow
                                    key={row.ID_PR}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{row.ID_PR}</TableCell>
                                    <TableCell align="left">{row.Nom_PR}</TableCell>
                                    <TableCell align="left">{row.Prenom_PR}</TableCell>
                                    <TableCell align="left">{row.Role}</TableCell>
                                    <TableCell align="left">
                                        <Button variant="outlined" onClick={()=>{navigate("/Modifier-prop/"+row.ID_PR)}} color="warning" style={{ marginRight: '2px' }}>
                                            <EditIcon />
                                        </Button>
                                        <Button variant="outlined" color="error" onClick={()=>{handleDelete(row.ID_PR)}}>
                                            <DeleteIcon />
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                </CardContent>
            </Card>
            </>
    );
}
