// Building.js

import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import Img from '../img/porte.png';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const Building = ({ floors, dorsPerFlore, name, facade, surface,buildingHeight ,Numero,Etat,Prix }) => {
  const containerId = `container_${Math.random().toString(36).substring(7)}`;
  const tooltipRef = useRef();
  const navigate = useNavigate()
  const [cardContent, setCardContent] = useState({});
  const [activeDoor, setActiveDoor] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #D94E28',
    borderColor: '#D94E28',
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    var stage = new Konva.Stage({
      container: containerId,
      width: 420,
      height: buildingHeight +50,
    });
    var layer = new Konva.Layer();
    let addLine = false;
    for (let i = 0; i < floors; i++) {
      var flore = new Konva.Rect({
        x: 30,
        y:  buildingHeight - ((floors - (floors - i - 1)) * 142),
        width: 370,
        height: 130,
        fill: '#b3b1b1',
        stroke: 'black',
        strokeWidth: 2
      });
      layer.add(flore);

      flore.zIndex(1);

      const floorIndex = floors - (floors - i - 1);
      let floorName;
      if (floorIndex === 1) {
        floorName = 'R-2';
      } else if (floorIndex === 2) {
        floorName = 'R-1';
        addLine = true;
      } else if (floorIndex === 3) {
        floorName = 'RDC';
      } else {
        floorName = `Floor ${i - 3 + 1}`;
      }
      if (addLine && floorIndex === 2) {
        var line = new Konva.Line({
          y: -18,
          points: [20, flore.y() + 12, 410, flore.y() + 12],
          stroke: 'black',
          strokeWidth: 3
        });
        layer.add(line);
        addLine = false;
      }

      var floorText = new Konva.Text({
        x: flore.x() + 10,
        y: flore.y() + 90,
        text: floorName,
        fontSize: 20,
        fill: 'black',
        rotation: -90
      });
      layer.add(floorText);

      for (let j = 0; j < dorsPerFlore[i]; j++) {
        const dorX = flore.x() + ((j + 0.5) * (flore.width() - 50) / dorsPerFlore[i]);
        const dorY = flore.y() + 29;

        var dor = new Konva.Rect({
          x: dorX + 10,
          y: dorY - 10,
          width: 40,
          height: 90,
          fill: Etat[i][j] === "1"?'rgba(255, 0, 0, 0.5)':Etat[i][j] === "0"?'rgba(20, 255, 0, 0.5)':null,
          strokeWidth: 4
        });

        layer.add(dor);

        var dorImage = new window.Image();
        dorImage.src = Img;

        dorImage.onload = () => {

          var dorImageRect = new Konva.Rect({
            x: dorX,
            y: dorY - 10,
            width: 50,
            height: 90,
            fillPatternImage: dorImage,
            fillPatternScaleX: 0.25,
            fillPatternScaleY: 0.35,
            strokeWidth: 4
          });
          layer.add(dorImageRect);
          dorImageRect.moveToTop();
          var text = new Konva.Text({
            x: Numero[i][j].toString().length === 1 ? (dorX + 25) : Numero[i][j].toString().length === 2 ? (dorX + 19) :Numero[i][j].toString().length === 3 ? (dorX + 16) :(dorX + 16),
            y: dorY + 10,
            text: Numero[i][j].toString(),
            fontSize: Numero[i][j].toString().length === 1 ? 25 : Numero[i][j].toString().length === 2 ? 23 :Numero[i][j].toString().length === 3 ? 21 :20,
            fill: 'white',
            stroke: 'black',
            strokeWidth: 1.3,
          });
          layer.add(text);
          text.on('click', (e) => {
            handleOpen();
            setCardContent({
              surface:surface[i][j]+" m²",
              facade:facade[i][j],
              name,
              door:Numero[i][j],
              Prix:Prix[i][j]
            });
          });
          stage.batchDraw();

          dorImageRect.on('click', (e) => {
            handleOpen();
            setActiveDoor(dorImageRect);
            setCardContent({
              surface:surface[i][j]+" m²",
              facade:facade[i][j],
              name,
              door:Numero[i][j],
              Prix:Prix[i][j]
            });
          });
        };
      }
    }

    var tempText = new Konva.Text({
      text: name,
      fontSize: 20,
    });
    
    // Add the temporary text object to an invisible layer to avoid rendering
    var invisibleLayer = new Konva.Layer();
    invisibleLayer.add(tempText);
    stage.add(invisibleLayer);
    
    // Measure the width of the temporary text object
    var textWidth = tempText.width();
    
    // Calculate the x-coordinate to center the text horizontally
    var centerX = (stage.width() - textWidth) / 2;
    
    // Remove the temporary text object and invisible layer
    invisibleLayer.destroy();
    
    // Create the actual buildingNameText with the calculated x-coordinate
    var buildingNameText = new Konva.Text({
      x: centerX + 5,
      y: buildingHeight + 15,
      text: name,
      fontSize: 20,
      fill: 'black',
      stroke: 'black',
      strokeWidth: 2
    });
    layer.add(buildingNameText);

    var buildingNameBorder = new Konva.Rect({
      x: 30,
      y: buildingHeight - 1,
      width: flore.width(),
      height: buildingNameText.height() + 30,
      stroke: 'black',
      strokeWidth: 2,
      cornerRadius: 10
    });
    layer.add(buildingNameBorder);
    stage.add(layer);

    var tooltipLayer = new Konva.Layer();
    stage.add(tooltipLayer);


    var tooltip = new Konva.Label({
      opacity: 0.75,
      visible: false,
      listening: false,
      zIndex: 3
    });
    tooltip.add(
      new Konva.Tag({
        fill: 'black',
        pointerDirection: 'down',
        pointerWidth: 10,
        pointerHeight: 10,
        lineJoin: 'round',
      })
    );
    tooltip.add(
      new Konva.Text({
        text: '',
        fontFamily: 'Arial',
        fontSize: 12,
        padding: 5,
        fill: 'white',
      })
    );
    tooltipRef.current = tooltip;
    tooltipLayer.add(tooltip);

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [floors, dorsPerFlore, containerId]);

  const handleOutsideClick = (event) => {
    if (!tooltipRef.current || !tooltipRef.current.getStage()) return;
    const stage = tooltipRef.current.getStage();
    if (!stage) return;
    const clickedNode = stage.findOne('.' + event.target.className);
    if (!clickedNode) {
      if (activeDoor) {
        activeDoor.visible(false);
        setActiveDoor(null);
      }
    }
  };


  return (
    <>
      <div id={containerId} style={{display:"flex" , justifyContent:"center" , marginTop:"-10%"}} ></div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          immeuble :{cardContent.name}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Door Number :{cardContent.door}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Facade :{cardContent.facade}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Surface :{cardContent.surface}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Prix :{parseInt(cardContent.Prix)} DH
          </Typography>
          <Typography id="modal-modal-description" sx={{ display:"flex" , justifyContent:"center",mt:3 }}>
          <Button variant='outlined' onClick={()=>navigate("/HistoriqueAppartement")}>Historique de Appartement</Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default Building;
