import React from "react";
import { Route, Routes} from "react-router-dom";
import Ajouterprop from "./Components/AjouterProp.js";
import Ajouterimbl from "./Components/Ajouterimbl.js";
import Modifierimbl from "./Components/Modifierimbl.js";
import Modifierprop from "./Components/ModifierProp.js";
import Ajoutertype from "./Components/Typeprop.js";
import Dashboard from "./Components/Dashboard.js";
import { createTheme, ThemeProvider } from '@mui/material';
import UtilisTable from './Components/TableUtilisateur';
import SignIn from "./Components/SignIn.js";
import ListImobilier from "./Components/ListImobilier.js";
import HistoriqueAppartement from "./Components/HistoriqueAppartement.js";
import GestionImobilier from "./Components/GestionImobilier.js";


const theme = createTheme({
  palette: {
    primary: {
      main: '#D94E28', 
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<SignIn/>} />
        <Route path="/Ajouter-prop" element={<Ajouterprop/>} />
        <Route path="/propritaire" element={<UtilisTable/>} />
        <Route path="/Modifier-prop/:id" element={<Modifierprop/>} />
        <Route path="/Ajouter-imbl" element={<Ajouterimbl/>} />
        <Route path="/Modifier-imbl" element={<Modifierimbl/>} />
        <Route path="/listimbl" element={<ListImobilier/>} />
        <Route path="/type-proprieter" element={<Ajoutertype/>} />
        <Route path="/Dashboard" element={<Dashboard/>} />
        <Route path="/HistoriqueAppartement" element={<HistoriqueAppartement/>} />
        <Route path="/GestionImobilier" element={<GestionImobilier/>} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
